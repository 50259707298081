.header--market-place-1 .header__extra span i {
  color: #000;
  font-weight: 700;
}

.header--market-place-1 .header__extra:hover > i {
  color: #fcb800;
}

.header--market-place-1 .header__extra:hover span {
  color: #fcb800;
}

.header--market-place-1 .header__content .menu--product-categories {
  display: none;
}

.header--market-place-1 .navigation {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  background-color: #fff;
}

.header--market-place-1 .navigation > .container {
  display: flex;
  flex-flow: row nowrap;
}

.header--market-place-1 .navigation > .container > * {
  width: 100%;
}

.header--market-place-1.header--sticky .header__content .menu--product-categories {
  display: block;
}

.ps-banner--market-1 {
  position: relative;
}

.ps-banner--market-1 h5 {
  color: red;
  font-weight: 400;
}

.ps-banner--market-1 h3 {
  font-size: 36px;
  line-height: 1.2em;
  font-weight: 400;
}

.ps-banner--market-1 p {
  margin-bottom: 0;
  font-size: 14px;
  color: #999999;
  line-height: 1.8em;
}

.ps-banner--market-1 p strong {
  display: block;
  font-size: 24px;
  font-weight: 500;
  color: #669900;
}

.ps-banner--market-1 .ps-btn {
  margin-top: 20px;
  padding: 12px 24px;
}

.ps-banner--market-1 .ps-banner__content {
  max-width: 1200px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 220px;
  padding-right: 50px;
}

@media (max-width: 1680px) {
  .ps-banner--market-1 h4 {
    margin-bottom: 10px;
  }
  .ps-banner--market-1 h3 {
    font-size: 40px;
    line-height: 1.2em;
  }
}

@media (max-width: 1366px) {
  .ps-banner--market-1 h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .ps-banner--market-1 h3 {
    font-size: 40px;
  }
  .ps-banner--market-1 h3 br {
    display: none;
  }
}

@media (max-width: 1199px) {
  .ps-banner--market-1 {
    min-height: 400px;
  }
  .ps-banner--market-1 img {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .ps-banner--market-1 .ps-banner__content {
    padding: 0 30px;
  }
}

@media (max-width: 479px) {
  .ps-banner--market-1 h3 {
    font-size: 24px;
  }
  .ps-banner--market-1 p {
    font-size: 12px;
  }
}

#homepage-3 .ps-home-banner .owl-slider .owl-nav {
  left: 50%;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#homepage-3 .ps-home-banner .owl-slider .owl-nav > * {
  width: 48px;
  height: 48px;
}

@media (max-width: 991px) {
  #homepage-3 .ps-home-banner .ps-banner--market-1 {
    background-size: cover;
    background-position: 50% 50% !important;
  }
}

#homepage-3 .ps-site-features {
  padding: 60px 0;
}

@media (max-width: 767px) {
  #homepage-3 .ps-site-features {
    padding: 40px 0;
  }
}

@media (max-width: 479px) {
  #homepage-3 .ps-site-features {
    padding: 30px 0;
  }
}

#homepage-3 .ps-promotions .ps-collection {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

@media (max-width: 767px) {
  #homepage-3 .ps-promotions .ps-collection {
    margin-bottom: 30px;
  }
}

#homepage-3 .ps-deal-of-day {
  padding: 70px 0;
}

@media (max-width: 767px) {
  #homepage-3 .ps-deal-of-day {
    padding: 45px 0;
  }
}

@media (min-width: 1200px) {
  #homepage-3 .ps-container {
    max-width: 1200px;
  }
}
